<template>
  <div>
    <vs-row style="width: 50%; margin: auto">
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
        class="w-full"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <vs-col
            class="mb-2"
            vs-w="12"
          >
            <tm-select
              name="التاغ"
              :options="tags"
              validation-rules="required"
              v-model="tagId"
              label="name"
              :reduce="name => name.id"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              ref="loadableButton"
              id="button-with-loading"
              class="vs-con-loading__container"
              color="primary"
              type="filled"
              size="small"
              icon-pack="feather"
              icon="icon-plus"
              @click.prevent="handleSubmit(onSubmit)"
              :disabled="disabled"
            >
              اضافة
            </vs-button>
          </vs-col>
        </form>
      </ValidationObserver>
    </vs-row>
    <vs-divider>التاغات</vs-divider>
    <div class="mb-6">
      <vs-chip
        v-for="(tag, index) in subProduct.tags"
        :key="index"
        @click="deleteTag(tag.sub_product_tag_id)"
        transparent
        color="primary"
        closable
      >
        {{ tag.name }}
      </vs-chip>
    </div>
  </div>
</template>

<script>
import TmSelect from '../../../shared/shared-components/TmSelect';
import * as websiteFactory from '../../../website/website-repositories/websiteRepositoryFactory';
import {RepositoryFactory} from '../../repositories/ecommerceRepositoryFactory';

const tagRepository = websiteFactory.RepositoryFactory.get('tag');
const subProductRepository = RepositoryFactory.get('subProductTag');
export default {
  name: "SubProductTag",
  props: {
    subProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tags: [],
      tagId: null,
      disabled: false,
      backgroundLoading: 'primary',
      colorLoading: '#fff',
    };
  },

  methods: {
    deleteTag(subProductTagId) {
      subProductRepository.delete(subProductTagId).then((response) => {
        if (response.code === 200) {
          let tagIndex = this.subProduct.tags.findIndex(tag => tag.sub_product_tag_id === subProductTagId);
          this.subProduct.tags.splice(tagIndex, 1);
        }
      });
    },
    load() {
      this.disabled = true;
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45
      });
    },
    closeLoading() {
      this.disabled = false;
      this.$vs.loading.close("#button-with-loading > .con-vs-loading");
    },
    onSubmit() {
      this.load();
      let subProductTag = {
        sub_product_id: this.subProduct.id,
        tag_id: this.tagId
      };
      subProductRepository.store(subProductTag).then((response) => {
        response.data.tag.sub_product_tag_id = response.data.id;
        this.subProduct.tags.push(response.data.tag);
        this.closeLoading();
      });
    }
  },

  components: {
    TmSelect
  },

  created() {
    tagRepository.fetchAllTags('Ecommerce').then((response) => {
      this.tags = response;
    });
  }
};
</script>

